import React from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withStyles } from "@material-ui/core/styles";
import _ from 'lodash'
import ReactTooltip from 'react-tooltip'
import { FilePicker } from 'react-file-picker'

import { ThemeProvider } from '@material-ui/styles';
import LinearProgress from '@material-ui/core/LinearProgress';

import { dispatchStyles } from '../../../modules/resources/styles'
import {
  clearState,
  getSites,
  getGroups,
  filterSites,
  driverSelect,
  setInputDispatch,
  createDispatches,
  addNewSiteFromCreate,
  importSites,
  getSitesList,
  getDispatches,
  closeImportSitesErrorModal

} from '../../../modules/core/Dispatch/actions'
import { setInputUser } from '../../../modules/core/User/actions'

import { colors } from '../../../modules/resources/colors'
import { mainTheme } from '../../../modules/resources/themes'
import { string_general, string_dispatch } from '../../../modules/resources/strings'


import Dropdown from '../../components/Dropdown'
import AutocompleteMui from '../../components/AutocompleteMui'
import Button from '../../components/Button'
import Table from '../../components/TableCreateDispatches'
import Modal from '../../components/Modal'
import Loading from '../../components/Loading'
import Alert from '../../components/Alert'

import AddSite from '../Site/CreateSite'

import MapContainer from './Map'

import { hide_map, show_map, hide_table, show_table } from '../../../modules/resources/images'
import { showAlert } from '../../../modules/resources/Util'
import Switch from "react-switch";
import Swal from 'sweetalert2';

const styles = {
  root: {
    background: "black"
  },
  input: {
    color: "black",
    background: colors.bg_input
  }
}

/**
 * Function to download the report bad import sites report
 * @param {*} errorReport Error message got it from the API Request
 */
const downloadFileReport = (errorReport) => {
  let element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(errorReport));
  element.setAttribute('download', "Report-Import-Sites-Failed.txt");

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

class CreateDispatch extends React.Component {

  state = {
    numberPages: 100,
    loading: true,
    customerSelected: this.props.idCustomerSelected,
    siteSelected: 0,
    customerlabel: '',
    typeSelected: 1,
    groupSelected: 0,
    groupLabel: 'All',

    showMap: true,
    showTable: true,
    showModal: false,
    hoverKey: 0,

    showAlert: false,
    titleAlert: '',
    textContentAlert: '',
    onOkAlert: () => { },

    importing: false,
    bulkStateChecked: false,
    driverSelected: '',
    sitesFilter: [
      {
        value: 0,
        description: 'All'
      }
    ],
    sitesFilteredBackup: [],
    dispatches: []
  }



  handleChange(value) {
    
    if (this.state.groupSelected === 0 && this.state.bulkStateChecked === false) {

      showAlert({
        msj: 'Select a Group',
        type: 2
      })

    } else {
      this.setState({ bulkStateChecked: !this.state.bulkStateChecked });

    }
  }

  componentDidMount() {
    if (!this.props.isEdited) {
      this.props.getSites({
        token: this.props.user.token,
        event: this.props.match.params.event,
        customer: this.props.idCustomerSelected
      })

      this.props.getSitesList({
        token: this.props.user.token,
        event: this.props.match.params.event,
        success: (val) => this.setState({ sitesFilter: val })
      })

      this.props.getDispatches({
        token: this.props.user.token,
        event: this.props.match.params.event,
        customer: this.props.idCustomerSelected,
        success: () => this.setState({ loading: false }),
        showError: (error) => { this.setState({ loading: false }) }
      })
    }

    this.configMenuItems()
    this.handleChange = this.handleChange.bind(this);
    

  }

  componentDidUpdate(prevProps, prevState) {
    if(!!prevState.siteSelected && this.props.sitesFilter.length == 1 && this.state.sitesFilteredBackup.length <= 0) {
      this.setState({sitesFilteredBackup: prevProps.sitesFilter})
    }
  }

  configMenuItems() {
    this.props.setInputUser({ prop: 'isEdited', value: false })
    const idEvent = this.props.match.params.event
    this.props.setInputUser({
      prop: 'itemsSubmenu', value: [{
        id: 1,
        label: 'Trucks',
        icon: 'tim-icons icon-delivery-fast',
        link: `/event/${idEvent}/trucks`
      },
      {
        id: 2,
        label: 'Drivers',
        icon: 'tim-icons icon-badge',
        link: `/event/${idEvent}/drivers`
      },
      {
        id: 3,
        label: 'Helpers',
        icon: 'tim-icons icon-single-02',
        link: `/event/${idEvent}/helpers`
      },
      {
        id: 4,
        label: 'Dispatch',
        icon: 'tim-icons icon-vector',
        link: `/event/${idEvent}/dispatches`
      },
      {
        id: 5,
        label: 'Deliveries',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/deliveries`

      },
      {
        id: 6,
        label: 'Transfers',
        icon: 'tim-icons icon-tag',
        link: `/event/${idEvent}/create_transfer`

      },

      {
        id: 7,
        label: 'Tracking',
        icon: 'tim-icons icon-world',
        link: `/event/${idEvent}/tracking`
      },
      {
        id: 8,
        label: 'Report',
        icon: 'tim-icons icon-notes',
        link: `/event/${idEvent}/reports`
      }]
    })
    this.props.setInputUser({ prop: 'value', value: 4 })
    this.props.setInputUser({ prop: 'itemSelect', value: 4 })

    this.props.setInputUser({
      prop: 'siteNavigations',
      value: [
        {
          link: `/events`,
          label: 'Events'
        },
        {
          link: `/events`,
          label: this.props.infoLabel
        },
        {
          link: `/event/${this.props.match.params.event}/dispatches`,
          label: 'Dispatches'
        },
        {
          link: `/event/${this.props.match.params.event}/create_dispatch`,
          label: 'Create Dispatch'
        }]
    })
  }

  componentWillUnmount() {
    if (this.props.listItemsFilter.length !== 0) {
      if (!window.confirm(string_general.leaveMsjCreate)) {
        this.props.setInputUser({ prop: 'isEdited', value: true })
        this.props.history.push(`/event/${this.props.match.params.event}/create_dispatch`)
      } else {
        this.props.clearState()
      }
    }
  }



  render() {
    return (
      this.props.creatingDispatches ?
        <Loading label={string_dispatch.creating_dispatch} />
        :
        <div style={dispatchStyles.container}>

          <div style={{
            display: this.state.showMap ? 'block' : 'none', flexDirection: 'column'
          }}>

            <div style={dispatchStyles.containerFilters}>
              <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                width: '90%',
              }} >
                <div style={dispatchStyles.containerDropdownFilters}>
                  <div style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                    {/* CUSTOMERS */}
                    <label style={{ marginRight: 10, marginTop: 3 }}>{`Set ${string_general.label_dropdown_customers}`} </label>
                    <Dropdown
                      selectItem={this.state.customerSelected}
                      placeholder={this.props.labelCustomers}
                      options={this.props.customers}
                      disabled={this.props.idCustomerSelected !== 0}
                      onChange={(value, description) => {
                        this.setState({ customerSelected: value, customerlabel: description, siteSelected: 0 })
                        setTimeout(() => {
                          this.props.filterSites({
                            sites: this.props.sites,
                            customer: value,
                            group: this.state.groupSelected,
                            groupLabel: this.state.groupLabel,
                            siteId: this.state.siteSelected
                          })
                        }, 200);

                      }} />

                    {/* SITES  */}

                    <label style={{ marginRight: 10, marginTop: 3 }}>{`Set ${string_general.label_dropdown_sites}`} </label>
                    <AutocompleteMui
                      styles={true}
                      selectItem={this.state.siteSelected}
                      data={this.props.sitesFilter}
                      dataSelector="name"
                      label={this.props.labelSites}
                      onChange={(siteId, description, siteSelected) => {
                        if(this.state.customerSelected == 0 || !this.state.customerSelected){
                          Swal.fire({
                            title: "You must select a custormer first",
                            icon: "warning",
                          })
                          return;
                        }
                        
                        if (!siteId || siteId == this.state.siteSelected.id) {
                          //In case no select or erase the selection
                          this.setState({ siteSelected: 0, labelSites: this.props.labelSites })
                          this.props.filterSites({
                            sites: this.state.sitesFilteredBackup,
                            customer: 0,
                            group: 0,
                            groupLabel: 0,
                            siteId: 0
                          })
                          this.setState({sitesFilteredBackup: []})
                        } else {
                          //In case selecte an item
                          if(this.state.sitesFilteredBackup.length == 0) {
                            //First time selecting
                            this.setState({sitesFilteredBackup: this.props.sitesFilter});
                          }
                          this.setState({ siteSelected: siteSelected, labelSites: description })
                          setTimeout(() => {
                            this.props.filterSites({
                              sites: this.props.sitesFilter,
                              customer: this.state.customerSelected,
                              group: this.state.groupSelected,
                              groupLabel: this.state.groupLabel,
                              siteId: siteId
                            })
                          }, 500);
                        }
                      }}
                    />

                    {/* DISPATCH TYPE */}
                    <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>Set Type </label>
                    <Dropdown
                      selectItem={this.state.typeSelected}
                      placeholder={this.props.labelDispatchTypes}
                      options={this.props.dispatchTypes}
                      onChange={(value, description) => {
                        this.setState({ typeSelected: value })
                      }} />

                    {/* GROUP NUMBER */}
                    <label style={{ marginRight: 10, marginLeft: 30, marginTop: 3 }}>{string_general.label_dropdown_group} </label>
                    <Dropdown
                      selectItem={this.state.groupSelected}
                      placeholder={this.props.labelGroups}
                      options={this.props.groups}
                      onChange={(value, description) => {
                        this.setState({
                          groupSelected: value,
                          groupLabel: description
                        })
                        this.props.filterSites({
                          sites: this.props.sites,
                          customer: this.state.customerSelected,
                          group: value,
                          groupLabel: description,
                          siteId: this.state.siteSelected
                        })
                      }} />

                    <div style={{
                      marginLeft: 20
                    }}>

                      <FilePicker
                        style={{ display: 'none' }}
                        extensions={['xlsx']}
                        webkitdirectory
                        onChange={FileObject => {
                          this.setState({
                            onOkAlert: () => {
                              this.setState({ showAlert: false })
                              this.props.importSites({
                                token: this.props.user.token,
                                fileTemp: FileObject,
                                idCustomer: this.state.customerSelected,
                                drivers: this.props.drivers,
                                event: this.props.match.params.event,
                                setImporting: (importing) => this.setState({ importing, bulkStateChecked: true }),
                              })

                              let that = this;
                              let startTime = new Date().getTime();
                              let interval = setInterval(function () {
                                if (new Date().getTime() - startTime > 10000) {
                                  clearInterval(interval);
                                  return;
                                }
                                that.props.getGroups({
                                  token: that.props.user.token,
                                  event: that.props.match.params.event
                                })
                              }, 2000);



                            }
                          })

                          if (this.props.listItemsFilter.length !== 0) {
                            this.setState({
                              showAlert: true,
                              titleAlert: 'Alert',
                              textContentAlert: 'You have created dispatches. If you import new sites, previously created dispatches will be deleted. Do you want import new sites?'
                            })
                          } else {
                            this.setState({
                              showAlert: true,
                              titleAlert: 'Alert',
                              textContentAlert: 'Only sites contained in the import spreadsheet will be displayed within the Dispatch Map. They will also be added to Customer/Sites.'
                            })
                          }
                        }}
                        onError={errMsg => {
                          showAlert({
                            msj: errMsg,
                            type: 3
                          })
                        }}
                      >
                        <button ref={button => this.buttonElement = button}>
                          {string_general.button_import}
                        </button>
                      </FilePicker>

                      <button style={dispatchStyles.buttonImport} onClick={() => {
                        if (this.state.customerSelected === 0) {
                          showAlert({
                            msj: 'Select a customer',
                            type: 2
                          })
                        } else {
                          this.buttonElement.click()
                        }
                      }}>
                        {string_general.button_import}
                      </button>

                      <button style={dispatchStyles.buttonImport}
                        onClick={() => {
                          if (this.state.customerSelected !== 0) {
                            this.setState({ showModal: true })
                          } else {
                            showAlert({
                              msj: 'Select a customer',
                              type: 2
                            })
                          }
                        }

                        }>
                        {string_general.button_create_sites}
                      </button>


                    </div>

                  </div>
                </div>
              </div>
              <div style={dispatchStyles.containerButtonCreate}>

                <Button
                  onPress={() => this.props.createDispatches({
                    token: this.props.user.token,
                    event: this.props.match.params.event,
                    dispatches: this.props.listItems,
                    history: this.props.history
                  })}
                  width={'95%'}
                  label={string_general.button_submit_dispatch}
                  fontSize={12}
                />

              </div>
            </div>

            <div style={dispatchStyles.containerFilters}>
              <div style={{ marginRight: 20 }}>
                <label style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                  textAlign: 'center'
                }}>
                  <span style={{ display: 'block', width: '100%' }}>Bulk Driver Asssigment</span>
                  <Switch onChange={this.handleChange} checked={this.state.bulkStateChecked} />
                </label>
              </div>



              <div style={dispatchStyles.containerDropdownFilters}>
                <div style={{
                  display: 'flex',
                  width: '80%',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}>

                  {/* DRIVERS */}
                  <label style={{ marginRight: 10, marginTop: 3 }}>{`Set ${string_general.label_dropdown_drivers}`} </label>
                  <Dropdown
                    selectItem={this.state.driverSelected}
                    placeholder={this.props.labelCustomers}
                    options={this.props.drivers}
                    onChange={(value, description, item) => {
                      
                      if (this.state.bulkStateChecked === false) {
                        showAlert({
                          msj: 'Enable the Bulk Driver Assignment',
                          type: 2
                        })

                      } else if (this.state.groupSelected === 0) {
                        showAlert({
                          msj: 'Please select a group',
                          type: 2
                        })
                      }

                      else {
                        this.setState({
                          driverSelected: value
                        })

                        item['id'] = item["value"];
                        item['isSelect'] = false;
                        item['typeDispatch'] = 0;

                        this.props.sitesFilter.forEach(async (element) => {
                          let idSite = element['id'];
                          
                          setTimeout(() => {
                            this.props.driverSelect({
                              sites: this.props.sites,
                              sitesFilters: this.props.sitesFilter,
                              type: this.state.typeSelected,
                              idSite,
                              item,
                              listItems: this.props.listItems,
                              listItemsFilter: this.props.listItemsFilter
                            })
                          }, 3000);

                        });


                      }

                    }} />
                </div>
              </div>
            </div>

            {/* MAP */}
            <div style={{ height: '60%', width: '100%' }}>

              {this.state.importing && <ThemeProvider theme={mainTheme}>
                <LinearProgress color="primary" />
              </ThemeProvider>}

              <MapContainer
                places={this.props.sitesFilter}
                hoverKey={this.state.hoverKey}
                placesTaken={this.props.dispatchesFilters}
                onChangeHoverKey={(key) => this.setState({ hoverKey: key })}
                onSelectDriver={(idSite, item) => {
                  this.props.driverSelect({
                    sites: this.props.sites,
                    sitesFilters: this.props.sitesFilter,
                    type: this.state.typeSelected,
                    idSite,
                    item,
                    listItems: this.props.listItems,
                    listItemsFilter: this.props.listItemsFilter
                  })
                }
                }
              />

            </div>
          </div>

          <div style={{
            display: this.state.showTable ? 'block' : 'none', flexDirection: 'column',
            width: '100%'
          }}>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '99%',
              marginTop: 20
            }} >
              <div style={{
                display: 'flex',
                width: '25%',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center'
              }}>

              </div>
              <div style={dispatchStyles.containerButtonCreate}>
                <Button
                  onPress={() => this.props.createDispatches({
                    token: this.props.user.token,
                    event: this.props.match.params.event,
                    dispatches: navigator.userAgent.toLocaleLowerCase().indexOf('firefox') > 0 ? this.props.listItemsFilter : this.props.listItems,
                    history: this.props.history
                  })}
                  width={'100%'}
                  label={string_general.button_submit_dispatch}
                  fontSize={12}
                />
              </div>
            </div>

            <Table
              numberPages={this.state.numberPages}
              columns={this.props.columnsWithoutSortable}
              items={this.props.listItemsFilter}
              reorder={data => {
                this.props.setInputDispatch({ prop: "listItems", value: data })
                this.props.setInputDispatch({ prop: "listItemsFilter", value: data })
              }}
            />

          </div>


          {/* MODAL: ADD SITES AND ALERT*/}
          <ThemeProvider theme={mainTheme}>
            <Modal
              maxWidth='lg'
              open={this.state.showModal}
              close={() => this.setState({ showModal: false })}
              title={`${this.state.customerlabel}`}
              onPressAction={() => {
                this.setState({ showModal: false })
              }}
              buttonTitle='Ok'
            >
              <AddSite
                customer={this.state.customerSelected}
                createSiteFromEvent={(site) => this.props.addNewSiteFromCreate({
                  site,
                  sites: this.props.sites,
                  sitesFilter: this.props.sitesFilter,
                  drivers: this.props.drivers,
                  groupLabel: this.state.groupLabel
                })} />
            </Modal>

            <Alert
              open={this.state.showAlert}
              title={this.state.titleAlert}
              textContent={this.state.textContentAlert}
              onOk={() => this.state.onOkAlert()}
              onCancel={() => { this.setState({ showAlert: false }) }} />
          </ThemeProvider>

          {/* MODAL: IMPORTED SITES FAILED LIST*/}
          <ThemeProvider theme={mainTheme}>
            <Modal
              maxWidth='lg'
              open={this.props.importSitesFailed}
              close={() => this.props.closeImportSitesErrorModal()}
              title="Import sites failed"
              onPressAction={() => {
                downloadFileReport(this.props.sitesImportedError);
                this.props.closeImportSitesErrorModal();
              }}
              buttonTitle='Download report and close'
            >
              {/** HERE THE TABLE SCREEN */}

              <Table
                hidePaginationTop={true}
                numberPages={this.props.sitesRepeated.length}
                loading={false}
                columns={this.props.sitesRepeatedCols}
                items={this.props.sitesRepeated} />

            </Modal>

            <Alert
              open={this.state.showAlert}
              title={this.state.titleAlert}
              textContent={this.state.textContentAlert}
              onOk={() => this.state.onOkAlert()}
              onCancel={() => { this.setState({ showAlert: false }) }} />
          </ThemeProvider>

          {/* SHOW AND HIDE TABLE AND MAP*/}
          <div style={dispatchStyles.containerFloatButtons}
          >
            <ReactTooltip place="bottom" type="dark" effect="float" id='map' >
              <span>{this.state.showMap ? 'Hide Map' : 'Show Map'}</span>
            </ReactTooltip>

            <div data-tip data-for='map' style={dispatchStyles.floatButton}
              onClick={() => this.setState({ showMap: !this.state.showMap })}>
              <img src={this.state.showMap ? show_map : hide_map} style={{ width: 25, height: 25 }} />
            </div>


            <ReactTooltip place="bottom" type="dark" effect="float" id='table' >
              <span>{this.state.showTable ? 'Hide Table' : 'Show Table'}</span>
            </ReactTooltip>
            <div data-tip data-for='table' style={dispatchStyles.floatButton}
              onClick={() => this.setState({ showTable: !this.state.showTable })}>
              <img src={this.state.showTable ? show_table : hide_table} style={{ width: 20, height: 20 }} />
            </div>

          </div>

        </div>
    )
  }
}

const mapStateProps = (state) => {
  const { isAuthenticated, user, idCustomerSelected, infoLabel, isEdited } = state.user
  const {
    columnsSortable, columnsWithoutSortable, listItems, listItemsFilter,
    creatingDispatches,

    labelCustomers, customers,
    labelDispatchTypes, dispatchTypes,
    labelSites, sites, sitesFilter, sitesf,
    labelDrivers, drivers,
    labelGroups, groups,
    sitesList, dispatches,
    dispatchesFilters,
    importSitesFailed, sitesImportedError,
    sitesRepeated, sitesRepeatedCols
  } = state.dispatch;

  return {
    isAuthenticated, user, idCustomerSelected, infoLabel, isEdited,
    creatingDispatches,

    columnsSortable, columnsWithoutSortable, listItems: _.orderBy(listItems, o => +o.priority, 'asc'), listItemsFilter,

    labelCustomers, customers,
    labelDispatchTypes, dispatchTypes,
    labelSites, sites, sitesFilter, sitesf,
    labelDrivers, drivers,
    labelGroups, groups,
    sitesList, dispatches, dispatchesFilters,
    importSitesFailed, sitesImportedError,
    sitesRepeated, sitesRepeatedCols

  }
}

export default withStyles(styles)(withRouter(connect(mapStateProps, {
  clearState,
  getSites,
  getSitesList,
  getGroups,
  filterSites,
  driverSelect,
  setInputDispatch,
  createDispatches,
  addNewSiteFromCreate,
  importSites,
  setInputUser,
  getDispatches,
  closeImportSitesErrorModal
})(CreateDispatch)))

